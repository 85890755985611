import React, { Suspense, useEffect, useState } from 'react';

import './Navbar.css';

import { Link, useLocation } from 'react-router-dom';

import $ from 'jquery';

import HomeImg from '../../../assets/images/home.PNG';
import CareerImg from '../../../assets/images/unnamed.jpg';

import SeaboardSideBar from './Seaboard_SideBar';

const Navbar = () => {

    const location = useLocation();
    const [ ShowX, setShowX ] = useState(false);
    
    const searchcancle = () =>{
        if ( $('.Menusearch').val().length > 0 )
            {
                setShowX( true )
                
            }else
            {

                setShowX( false )
            }

    }
    const clickcross = () =>{
        $('.Menusearch').val('');
        setShowX( false );
    }

    const OpenSidebar = () => {

        $('.Seaboard_SideBar .SideBar_Div').addClass('SHowSidebar');
        $('.Seaboard_SideBar .SideBar_Div-left').addClass('SHowSidebar');

    }

    const closeSideBar = () => {

        $('.Seaboard_SideBar .SideBar_Div').removeClass('SHowSidebar');
        $('.Seaboard_SideBar .SideBar_Div-left').removeClass('SHowSidebar');

    }

    const onSearch = ( e ) => {
        
        if ( e.keyCode === 13 )
        {
            let value = e.target.value;
            value = value.toLowerCase();
            let found = false;

            let peragraph = document.getElementsByTagName("p");
            let hs = 
                [
                    document.getElementsByTagName("h1"),
                    document.getElementsByTagName("h2"),
                    document.getElementsByTagName("h3"),
                    document.getElementsByTagName("h4"),
                    document.getElementsByTagName("h5"),
                    document.getElementsByTagName("h6")
                ];
            if ( !found )
            {
                for (let i = 0; i < peragraph.length; i++) {

                    if ( peragraph[i].innerText.toLowerCase().includes(value) )
                    {
                        found = true;
                        $('#searchTarget').removeAttr('id');
                        $('.input-search').val('');
                        $(peragraph[i].parentNode).attr('id', 'searchTarget');
                        document.getElementById('searchTarget').scrollIntoView();
                        // console.log(peragraph[i].parentNode);
                    }
    
                }
            }
            if ( !found )
            {
                for (let i = 0; i < hs.length; i++) {

                    for (let x = 0; x < hs[i].length; x++) {

                        if ( hs[i][x].innerText.toLowerCase().includes(value) )
                        {
                            found = true;
                            $('#searchTarget').removeAttr('id');
                            $('.input-search').val('');
                            $(hs[i][x].parentNode).attr('id', 'searchTarget');
                            document.getElementById('searchTarget').scrollIntoView();
                            // console.log(hs[i][x].parentNode);
                        }
        
                    }
    
                }
            }
        }
        
    }

    // useEffect(
    //     () => {

    //         let arr = [ 'Seaboard_Companies' ];
    //         let link = window.location.href.split('/').pop();

    //         if ( $("html").scrollTop() < 10 )
    //         {
    //             console.log( link );
    //             console.log( arr.includes(link) );
    //             if ( arr.includes(link) )
    //             {
    //                 $(".SeaBoard_Home").removeClass('scrolled').removeClass('topped').addClass('topped-dark');
    //             }else
    //             {
    //                 $(".SeaBoard_Home").removeClass('scrolled').removeClass('topped-dark').addClass('topped');
    //             }
    //         }
            
    //         document.addEventListener('scroll', () => {

    //             link = window.location.href.split('/').pop();

    //             if ( $("html").scrollTop() < 10 )
    //             {
    //                 if ( arr.includes(link) )
    //                 {
    //                     $(".SeaBoard_Home").removeClass('scrolled').addClass('topped-dark');
    //                 }else
    //                 {
    //                     $(".SeaBoard_Home").removeClass('scrolled').addClass('topped');
    //                 }
    //             }else
    //             {
    //                 $(".SeaBoard_Home").removeClass('topped').addClass('scrolled');
    //             }
      
    //         })

    //     }, [ location ]
    // )

    return (
        <>
            <Suspense fallback={ <div>Loading...</div> }>
                <SeaboardSideBar OpenSidebar={ OpenSidebar } closeSideBar={ closeSideBar } />
            </Suspense>
            <div className="SeaBoard_Home ">

               
                <div className="SeaBoard_Logo">
                    <div>
                        <Link to="/">
                            {/* <img src={ Logo } alt='logo' /> */}
                            <h3>
                                SEABOARD 
                            </h3>
                        </Link>
                    </div>
                </div>
                <div className="SeaBoard_Bar first">
                    {/* <div className="search-box">
                        <button className="btn-search"> <i className="las la-search"></i></button>
                        <input type="text" className="input-search" onKeyDown={ e => onSearch( e ) } placeholder="Type to Search..." />
                    </div> */}
<div className="search-button">
    <input type="search" name="search" id="search"  onKeyDown={ e => onSearch( e ) } placeholder="Search..." />
    <button className="search-btns">
        <i className="las la-search"></i>
    </button>
</div>


                    <div className='login_button'>

                    <a href='https://portal.seaboard.pk/#/login' rel="noreferrer" target="_blank" className="empPortalLink">Login</a>
                    </div>
                    <div className="d-flex align-items-center elements menu">
                        <button type="button" className="form-control mnuBtn" onMouseOver={ OpenSidebar } onMouseOut={ closeSideBar }> <i className="las la-bars"></i> </button>
                        <div className="mnudropdown">

                            <div className="mnudropdownDataOnLarge">
                                <div className="cols border-right">
                                    <img src={ HomeImg } alt="about img" />
                                    <Link to="/" className="links font-weight-bold mb-3">Home</Link>
                                    {/* <Link to="/ourhistory" className="links">History</Link>
                                    <Link to="/seaboard_family" className="links">Seaboard Family</Link>
                                    <Link to="/values" className="links">Values & Philosophy</Link> */}
                                </div>
                                <div className="cols">
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaNbbSQlGflPrdPLFZCX1Gr1JBv5pQIQp3Jg&usqp=CAU" alt="ideas img" />
                                    <Link to="/ideas" className="links font-weight-bold mb-3">Seaboard Logistics</Link>
                                    <Link to="/covid-19" className="links">Seaboard Services</Link>
                                    <Link to="/media" className="links">Qasim Freight Station</Link>
                                    <Link to="/media" className="links">City Transport</Link>
                                    <Link to="/media" className="links">Seatech</Link>
                                    <Link to="/media" className="links">KDC</Link>
                                </div>
                                <div className="cols border-right">
                                    <img src={CareerImg} alt="work img" />
                                    <Link to="/careers" className="links font-weight-bold mb-3">Careers</Link>
                                    <Link to="/contact" className="links">Contact</Link>
                                </div>
                            </div>
                            <div className="mnudropdownDataOnSmall">
                                <div className="DIV1 mb-4">
                                    <input type="text" placeholder="Search Keywords" className="form-control Menusearch" onChange={searchcancle} />
                                    {
                                        !ShowX
                                        ?
                                        <i className="las la-search"></i>
                                        :
                                        <i class="las la-times" onClick={clickcross}></i>
                                    }
                                </div>
                                <a className="btn btn-block mb-3 border-bottom" href='https://192.168.100.14/' role="button">
                                    Employee Portal
                                </a>
                                <a className="btn btn-block mb-3 border-bottom" data-toggle="collapse" href="#AboutUs" role="button" aria-expanded="false" aria-controls="AboutUs">
                                    Navigations
                                </a>
                                <div className="collapse" id="AboutUs">
                                    <div className="card card-body">
                                        <img className="mb-3" src={ HomeImg } alt="about img" />
                                        <Link to="/" className="links text-dark font-weight-bold mb-3">Home</Link>
                                        <Link to="/ourhistory" className="links text-dark">History</Link>
                                        <Link to="/seaboard_family" className="links text-dark">Seaboard Family</Link>
                                        <Link to="/values" className="links text-dark">Values & Philosophy</Link>
                                    </div>
                                </div>
                                <a className="btn btn-block mb-3 border-bottom" data-toggle="collapse" href="#Ideas" role="button" aria-expanded="false" aria-controls="Ideas">
                                    Companies
                                </a>
                                <div className="collapse" id="Ideas">
                                    <div className="card card-body">
                                        <img className="mb-3" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaNbbSQlGflPrdPLFZCX1Gr1JBv5pQIQp3Jg&usqp=CAU" alt="ideas img" />
                                        <Link to="/ideas" className="links text-dark font-weight-bold mb-3">Seaboard Logistics</Link>
                                        <Link to="/covid-19" className="links text-dark">Seaboard Services</Link>
                                        <Link to="/media" className="links text-dark">Qasim Freight Station</Link>

                                        <Link to="/media" className="links">City Transport</Link>
                                        <Link to="/media" className="links">Seatech</Link>
                                        <Link to="/media" className="links">KDC</Link>
                                    </div>
                                </div>
                                <a className="btn btn-block mb-3 border-bottom" data-toggle="collapse" href="#OurWork" role="button" aria-expanded="false" aria-controls="OurWork">
                                    Careers
                                </a>
                                <div className="collapse" id="OurWork">
                                    <div className="card card-body">
                                        <img className="mb-3" src={CareerImg} alt="work img" />
                                        <Link to="/careers" className="links text-dark font-weight-bold mb-3">Careers</Link>
                                        <Link to="/contact" className="links">Contact</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
