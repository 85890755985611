/* eslint-disable jsx-a11y/no-access-key */
import React from 'react';
import { Link } from 'react-router-dom';
import './SeaBoard_Footer.css';

import Logo from '../../../assets/images/Logo.png';

const SeaBoard_Footer = () => {
    return (
        <>
            <div className="SeaBoard_Footer">
                <div className="SeaBoard_Footer_Details larger">
                    <div className="">
                        <img src={ Logo } width="70%" alt="footer Logo" />
                    </div>
                    <div className="mb-4 cols">
                        <h5 style={{fontFamily:"Gilroy-regular"}} className="mb-0">Navigation</h5>
                        <div className="SeaBoard_Line mt-2 mb-3"></div>
                        <p>
                            <Link to="/"  className="links">
                                Home
                            </Link>
                        </p>
                        {/* <p>
                            <Link to="/about_us" className="links">
                                About Us
                            </Link>
                        </p> */}
                        <p>
                            <Link to="/seaboard_family"  className="links">
                                Our People
                            </Link>
                        </p>
                        <p>
                            <Link to="/Seaboard_Companies"  className="links">
                                Seaboard Group
                            </Link>
                        </p>
                    </div>
                    <div className="mb-4 cols">
                        <h5 style={{fontFamily:"Gilroy-regular"}} className="mb-0">Get In Touch</h5>
                        <div className="SeaBoard_Line mt-2 mb-3"></div>
                        <p>
                            <Link to="/careers" className="links">
                                Join Us
                            </Link>
                        </p>
                        <p>
                            <Link to="/contact" className="links">
                                Contact
                            </Link>
                        </p>
                    </div>
                    {/* <div className="mb-4 cols">
                    </div> */}
                </div>
                {/* <h5 className="mb-0 followus-heading">FOLLOW US ON INSTAGARAM</h5>
<hr />
                <div className="SeaBoard_Footer_Icons">
                    <a target="_blank" href="##">
                        <i className="lab la-facebook-f"></i>
                    </a>
                    <a target="_blank" href="##">
                        <i className="lab la-instagram"></i>
                    </a>
                    <a target="_blank" href="##">
                        <i className="lab la-snapchat"></i>
                    </a>
                    <a target="_blank" href="##">
                        <i className="lab la-pinterest"></i>
                    </a>
                    <a target="_blank" href="##">
                        <i className="lab la-twitter"></i>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/seaboard/" rel="noreferrer">
                        <i className="lab la-linkedin"></i>
                    </a>
                </div> */}
                
            </div>
                <div className="text-center pt-3">
                    <p className="Copyrights">{ new Date().getFullYear() } &copy; seaboard.Group All Rights Reserved</p>
                </div>
        </>
    )
}
export default SeaBoard_Footer;
