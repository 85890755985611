import React from "react";
import './Up_Button.css';

const Up_Button = () => {
    return (
        <>
            <div className="Up_Button">
                <i className="las la-angle-up"></i>
            </div>
        </>
    )
}
export default Up_Button;