import React from 'react'
import { Link } from "react-router-dom";
import './Seaboard_SideBar.css';

import logo from '../../../assets/images/companies/Logo.jpg';

const Seaboard_SideBar = ( props ) =>{

    return (
        <>
            <div className="Seaboard_SideBar" >
                <div className="SideBar_Div" onMouseOver={props.OpenSidebar} onMouseOut={props.closeSideBar}>
                    <i className="las la-times" onClick={props.closeSideBar}></i>
                    <div className="py-5">
                        <Link to='/'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Home</h3>
                            </div>
                        </Link>
                        {/* <Link to='/about_us'>
                            <div className="SideBar_Div_Menu">
                                <h3 className=""> About Us </h3>
                            </div>
                        </Link> */}
                        <Link to='/seaboard_family'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Our People</h3>
                            </div>
                        </Link>
                        {/* <Link to='/ourhistory'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Our History</h3>
                            </div>
                        </Link> */}
                        <Link to='/values'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Values & Philosophy</h3>
                            </div>
                        </Link>
                        <Link to='/Seaboard_Companies'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Seaboard Group</h3>
                            </div>
                        </Link>
                        {/* <Link to='/gallery'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Seaboard Gallery</h3>
                            </div>
                        </Link> */}
                        <Link to='/careers'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Careers</h3>
                            </div>
                        </Link>
                        {/* <Link to='/blogs'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Blogs</h3>
                            </div>
                        </Link> */}
                        <Link to='/contact'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Contact</h3>
                            </div>
                        </Link>
                        
                        {/* <Link to='/login'>
                            <div className="SideBar_Div_Menu">
                                <h3 className="">Login</h3>
                            </div>
                        </Link> */}
                        <img src={logo} alt="logo" className="logo_in_sidebar" />
                    </div>
                </div>
                <div className='SideBar_Div-left' onMouseOver={props.OpenSidebar} onMouseOut={props.closeSideBar} >

                </div>

            </div>
        </>
    )
}
export default Seaboard_SideBar;